import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

export const NoteInsert = () => {
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [Note, setNote] = useState([]);

  useEffect(() => {
    // Get a reference to the database
    const database = firebase.database();

    // Get a reference to the "Note" location in the database
    const NoteRef = database.ref("Note");

    // Listen for changes to the "Note" location in the database
    NoteRef.on("value", (snapshot) => {
      // Get the data from the snapshot and convert it to an array of Note
      const NoteData = snapshot.val();
      const NoteArray = NoteData ? Object.entries(NoteData).map(([id, data]) => ({ id, ...data })) : [];

      // Update the state with the array of Note
      setNote(NoteArray);
    });

    // Return a cleanup function to remove the listener when the component unmounts
    return () => {
      NoteRef.off("value");
    };
  }, []);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Get a reference to the database
    const database = firebase.database();

    // Get a reference to the "Note" location in the database
    const NoteRef = database.ref("Note");

    // Generate a new Note ID
    const newNoteId = NoteRef.push().key;

    // Set the Note data at the new Note ID location in the database
    NoteRef.child(newNoteId).set({
      Question,
      Answer,
    });

    // Clear the form inputs
    setQuestion("");
    setAnswer("");
  };

  return (
    <div className="FormCOn">
      <form onSubmit={handleSubmit}>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Question
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Write Question"
            value={Question}
            onChange={handleQuestionChange}
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">
            Answer
          </label>
          <textarea
            class="form-control"
            value={Answer}
            onChange={handleAnswerChange}
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <div class="mb-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {Note.map((note) => (
            <tr key={note.id}>
              <td>{note.id}</td>
              <td>{note.Question}</td>
              <td>{note.Answer}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

