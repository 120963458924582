import React from 'react'
import { Link } from 'react-router-dom';

export const AnswerShow = () => {

  const Answer = localStorage.getItem('Local_Answer');
  const Question = localStorage.getItem('Local_Question');

  return (
    <>
    <Link to="/">
    <div className='Nav_back'>
        <div className="rig"><i class="fa-solid fa-chevron-left"></i></div>
        <div className="lef">English Solution</div>
        <div></div>
    </div>
    </Link>
    <br />
    <div className="container">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{Question}</h5>
        <p class="card-text" dangerouslySetInnerHTML={{__html:Answer}}></p>
      </div>
    </div>
    </div>
    <br />
    <br />
    <br />
    </>
  )
}
