import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
export const Home = () => {

  const [Note, setNote] = useState([]);
    useEffect(() => {
    // Get a reference to the database
    const database = firebase.database();

    // Get a reference to the "Note" location in the database
    const NoteRef = database.ref("Note");

    // Listen for changes to the "Note" location in the database
    NoteRef.on("value", (snapshot) => {
      // Get the data from the snapshot and convert it to an array of Note
      const NoteData = snapshot.val();
      const NoteArray = NoteData ? Object.entries(NoteData).map(([id, data]) => ({ id, ...data })) : [];

      // Update the state with the array of Note
      setNote(NoteArray);
    });

    // Return a cleanup function to remove the listener when the component unmounts
    return () => {
      NoteRef.off("value");
    };
  }, []);

    let navigate = useNavigate(); 
    const routeChange = (Question, Answer) =>{ 
      let path = `/answer`; 
      navigate(path);
      localStorage.setItem("Local_Answer", Answer);
      localStorage.setItem("Local_Question", Question);
    }

  return (
    <>
      <div className='Nav_back'>
        <div className="rig"></div>
        <div className="lef">English Solution</div>
        <div></div>
    </div>
    <div className="container_que">
  <div class="card">
    <ul class="list-group list-group-flush">

      {Note.map((note, index) => (
        <li class="list-group-item questHov" onClick={() => routeChange(note.Question, note.Answer)}>
          <div className="listCon">
          <div className="leftCon">{index + 1}.</div>
            <div className="rightCon"> {note.Question}</div>
          </div>
        </li>
      ))}

    </ul>
  </div>
</div>
</>
  )
}
