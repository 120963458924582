import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { Home } from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NotFound } from "./NotFound";
import { NoteInsert } from "./NoteInsert";
import { AnswerShow } from "./AnswerShow";

const firebaseConfig = {
  apiKey: "AIzaSyBIXsHgFYPjFCLJtYRNhGh8FYR9rnoJtg4",
  authDomain: "note-602b4.firebaseapp.com",
  databaseURL: "https://note-602b4-default-rtdb.firebaseio.com",
  projectId: "note-602b4",
  storageBucket: "note-602b4.appspot.com",
  messagingSenderId: "846329101555",
  appId: "1:846329101555:web:5a220411a4998590a74b86",
  measurementId: "G-54CLW0R0CY",
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <>
      <>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/insert" element={<NoteInsert />} />
            <Route path="/answer" element={<AnswerShow />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Router>
      </>
    </>
  );
}

export default App;
